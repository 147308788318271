/* eslint-disable consistent-return */
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

import {
	Button,
	Content,
	DescriptionPage,
	Header,
	Input,
	Loading,
	Steps,
	TextInfo,
	Wrapper
} from "components";
import { FiscalDocumentHelper, StringHelper } from "helpers";
import MaskHelper from "helpers/mask-helper";
import { triggerAnalyticsEvent } from "services/firebase";
import {
	AddressType,
	getCEPService,
	getServiceProvider
} from "services/service-provider";
import { RootState, store } from "store";
import { setRegistrationDirty } from "store/features/flags/slice";
import {
	setInitialPersonalInformation,
	setRegistrationData
} from "store/features/registration/slice";
import { RegistrationState } from "store/features/registration/types";

const RegistrationSchema = Yup.object().shape({
	name: Yup.string()
		.required("Campo obrigatório")
		.max(100, "Limite máximo de 100 caracteres ultrapassado.")
		.matches(/[a-zA-Z]+$/, "Campo inválido"),
	fiscal_document: Yup.string()
		.required("Campo obrigatório")
		.test("is-fiscal-doc-valid", "Documento inválido", (value) => {
			if (value.replace(/\.|-|\//g, "").length === 11) {
				return FiscalDocumentHelper.validateCPF(value);
			}
			if (value.replace(/\.|-|\//g, "").length === 14) {
				return FiscalDocumentHelper.validateCNPJ(value);
			}
		}),
	phone: Yup.string().required("Campo obrigatório"),
	address_postal_code: Yup.string().required("Campo obrigatório"),
	address_state: Yup.string(),
	address_city: Yup.string(),
	address: Yup.string(),
	address_neighborhood: Yup.string(),
	address_number: Yup.string().required("Campo obrigatório")
});

export default function PersonalInformationPage() {
	const dispatch = useDispatch();
	const personalData = store.getState().registration;
	const partnerUuid = store.getState().tokens.partner_uuid;
	const { isFirstAccess, isRegistrationDirty } = store.getState().flags;

	const [loadedAddress, setLoadedAddress] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [loadingPostalCode, setLoadingPostalCode] = useState<boolean>(false);
	const numberAddressRef = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();

	const { logged_user } = useSelector((state: RootState) => state.tokens);

	useEffect(() => {
		const fetchData = async () => {
			await getServiceProvider(partnerUuid).then((resp) => {
				if (resp) {
					const responseData = resp as RegistrationState;
					dispatch(setInitialPersonalInformation(responseData));
				}
			});
		};
		if (!isFirstAccess) {
			fetchData();
		}
	}, []);

	const emptyData = {
		name: "",
		fiscal_document: "",
		phone: "",
		address_postal_code: "",
		address_state: "",
		address_city: "",
		address: "",
		address_neighborhood: "",
		address_number: "",
		address_additional_info: ""
	};

	const formik = useFormik({
		initialValues:
			isFirstAccess && !isRegistrationDirty ? emptyData : personalData,
		validationSchema: RegistrationSchema,
		onSubmit: async ({
			phone,
			fiscal_document,
			address_postal_code,
			...rest
		}) => {
			setSubmitting(true);
			dispatch(
				setRegistrationData({
					...personalData,
					phone: StringHelper.sanitizeString(phone),
					fiscal_document: StringHelper.sanitizeString(fiscal_document),
					address_postal_code: StringHelper.sanitizeString(address_postal_code),
					...rest
				})
			);
			setSubmitting(false);
			triggerAnalyticsEvent("cp-set-personal-information");
			dispatch(setRegistrationDirty(true));
			navigate(`${logged_user ? "/dashboard" : ""}/cadastro/tipos-de-servicos`);
		}
	});

	useEffect(() => {
		if (personalData.address_postal_code) {
			setLoadedAddress(true);
		}
	}, []);

	const getAddress = async (cep: string) => {
		setLoadingPostalCode(true);
		await getCEPService(cep)
			.then((resp) => {
				setLoadingPostalCode(false);
				if (resp) {
					const addressObj = resp as AddressType;
					formik.setValues({
						...formik.values,
						address_postal_code: addressObj.cep,
						address_state: addressObj.uf,
						address_city: addressObj.localidade,
						address: addressObj.logradouro || "",
						address_neighborhood: addressObj.bairro || "",
						address_number: ""
					});
					setLoadedAddress(true);
					setTimeout(() => {
						numberAddressRef.current?.focus();
					}, 200);
				}
			})
			.catch((error) => {
				setLoadingPostalCode(false);
				formik.setFieldError("address_postal_code", "");
				toast.error("CEP Inválido", {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light"
				});
			});
	};

	const handleCEP = async (cep: string) => {
		const numericCep = cep.match(/\d+/g)?.join("") || "";
		if (numericCep.length === 8) {
			await getAddress(numericCep);
		} else {
			setLoadedAddress(false);
			formik.setValues({
				...formik.values,
				address_state: "",
				address_city: "",
				address: "",
				address_neighborhood: "",
				address_number: "",
				address_additional_info: ""
			});
		}
	};

	return (
		<>
			<Header title="Cadastro" />
			<Steps active={1} steps={3} />
			<DescriptionPage
				title="Informações básicas"
				description="Preencha seus dados pessoais, endereço comercial e aceite os termos e condições"
			/>
			<Wrapper>
				<form onSubmit={formik.handleSubmit}>
					<div className="flex flex-col gap-4 mb-10">
						<h3 className="heading_md">Dados pessoais</h3>
						<Content>
							<Input
								id="name"
								name="name"
								label="NOME COMPLETO*"
								type="text"
								error={formik.touched.name && formik.errors.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.name}
								errorMessage={formik.errors.name}
							/>
							<Input
								id="fiscal_document"
								name="fiscal_document"
								label="CPF/CNPJ*"
								type="tel"
								error={
									formik.touched.fiscal_document &&
									formik.errors.fiscal_document
								}
								errorMessage={formik.errors.fiscal_document}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={MaskHelper.cpfCnpj(
									String(formik?.values?.fiscal_document)
								)}
							/>
							<Input
								id="phone"
								name="phone"
								label="WHATSAPP*"
								type="tel"
								errorMessage={formik.errors.phone}
								error={formik.touched.phone && formik.errors.phone}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={MaskHelper.phone(String(formik.values.phone)) || ""}
							/>
						</Content>
					</div>
					<div className="flex flex-col gap-4 mb-10">
						<h3 className="heading_md">Endereço comercial</h3>
						<Content>
							<Input
								id="address_postal_code"
								name="address_postal_code"
								placeholder="00000-000"
								label="CEP*"
								type="tel"
								maxLength={9}
								error={
									formik.touched.address_postal_code &&
									formik.errors.address_postal_code
								}
								onChange={(e: any) => {
									handleCEP(e.target.value);
									formik.handleChange(e);
								}}
								onBlur={formik.handleBlur}
								value={MaskHelper.cep(
									String(formik.values.address_postal_code)
								)}
								errorMessage={formik.errors.address_postal_code}
								trailing={
									loadingPostalCode ? (
										<Loading size="small" className="h-auto justify-end pr-2" />
									) : undefined
								}
							/>
							{loadedAddress && (
								<>
									<div className="flex flex-col gap-7">
										<div className="flex gap-16">
											<TextInfo
												label="ESTADO"
												value={formik.values.address_state}
											/>
											<TextInfo
												label="CIDADE"
												value={formik.values.address_city}
											/>
										</div>
										<TextInfo label="RUA" value={formik.values.address} />
										<TextInfo
											label="BAIRRO"
											value={formik.values.address_neighborhood}
										/>
									</div>
									<Input
										id="address_number"
										name="address_number"
										label="NÚMERO*"
										type="text"
										error={
											formik.touched.address_number &&
											formik.errors.address_number
										}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.address_number}
										ref={numberAddressRef}
										errorMessage={formik.errors.address_number}
									/>
									<Input
										id="address_additional_info"
										name="address_additional_info"
										label="COMPLEMENTO"
										type="text"
										error={
											formik.touched.address_additional_info &&
											formik.errors.address_additional_info
										}
										onChange={formik.handleChange}
										value={formik.values.address_additional_info}
									/>
								</>
							)}
						</Content>
					</div>
					<Button
						type="submit"
						disabled={
							(formik.dirty && !formik.isValid) ||
							(!formik.dirty && !formik.isValid) ||
							(!formik.dirty && isFirstAccess && !isRegistrationDirty) ||
							submitting
						}
					>
						{submitting ? <Loading size="small" /> : "Prosseguir"}
					</Button>
				</form>
			</Wrapper>
		</>
	);
}
