import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import {
	IcoChat,
	IcoLogout,
	IcoMenuHamburger,
	IcoUser,
	LogoTrigo
} from "assets/icons";
import { store } from "store";
import { setInitialPersonalInformation } from "store/features/registration/slice";

import { SideBar, TopBar } from "components";
import { logout } from "services/auth";

import { getServiceProvider } from "services/service-provider";
import { RegistrationState } from "store/features/registration/types";
import SideBarItem from "./SideBarItem/SideBarItem";

import styles from "./styles.module.scss";

export default function ProviderLayout() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showSideBar, setShowSideBar] = useState(false);

	const doLogout = () => {
		setShowSideBar(!showSideBar);
		logout();
		navigate("/login");
	};

	const goToRegistration = async () => {
		setShowSideBar(!showSideBar);
		const partnerUuid = store.getState().tokens.partner_uuid;
		if (!partnerUuid) {
			navigate("/dashboard/cadastro/informacoes-pessoais");
			return;
		}

		await getServiceProvider(partnerUuid as string).then((resp) => {
			if (resp) {
				const personalData = resp as RegistrationState;
				dispatch(setInitialPersonalInformation(personalData));
			}
			navigate("/dashboard/cadastro/informacoes-pessoais");
		});
	};

	const getHeaderTitle = () => {
		const path = window.location.pathname;
		if (path.startsWith("/dashboard")) {
			return path.startsWith("/dashboard/cadastro/") ? "Cadastro" : "Mensagens";
		}
		return "";
	};

	return (
		<>
			<Helmet>
				<meta name="theme-color" content="#fff" />
			</Helmet>
			<SideBar show={showSideBar} toggle={() => setShowSideBar(!showSideBar)}>
				<SideBarItem
					clickable
					onClick={() => {
						setShowSideBar(!showSideBar);
						navigate("/dashboard");
					}}
				>
					<IcoChat />
					<span>Mensagem</span>
				</SideBarItem>
				<SideBarItem clickable onClick={goToRegistration}>
					<IcoUser />
					<span>Editar cadastro</span>
				</SideBarItem>
				<SideBarItem clickable onClick={doLogout}>
					<IcoLogout />
					<p>Sair</p>
				</SideBarItem>
			</SideBar>
			<div>
				<TopBar>
					<span />
					<LogoTrigo height="24" />
					<span />
				</TopBar>
				<TopBar>
					<span
						className={styles.menuIcon}
						onClick={() => setShowSideBar(true)}
					>
						<IcoMenuHamburger />
					</span>
					<span>{getHeaderTitle()}</span>
					<span />
				</TopBar>
			</div>
			<div className={styles.containerProviderLayout}>
				<Outlet />
			</div>
		</>
	);
}
