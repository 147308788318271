import { useFormik } from "formik";
import { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
	Button,
	ButtonBack,
	Content,
	DescriptionPage,
	Header,
	Input,
	Loading,
	ModalVideoPresentation,
	ProfilePictureUploader,
	ShallowInput,
	Steps,
	Textarea,
	Wrapper
} from "components";

import { IcoCalendar, IcoQuestionCircle } from "assets/icons";
import { triggerAnalyticsEvent } from "services/firebase";
import {
	createNewServiceProvider,
	saveServiceProvider
} from "services/service-provider";
import { RootState, store } from "store";
import { setSuperProfile } from "store/features/registration/slice";
import { SuperProfileState } from "store/features/registration/types";
import { placeholderProfilePicture } from "utils/constants";
import { validateUrl } from "utils/functions";

import { getPartnerUuid } from "services/auth";
import { Requester } from "services/requester";
import { setRegistrationDirty } from "store/features/flags/slice";
import Certifications from "./certifications";

const YOUTUBE_VIDEO_URL_REGEX =
	/^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;

export const validateYoutubeUrl = async (url: string): Promise<boolean> => {
	if (!YOUTUBE_VIDEO_URL_REGEX.test(url)) {
		return false;
	}

	const videoId = extractVideoId(url);
	if (!videoId) {
		return false;
	}

	const videoAvailable = await checkVideoStatus(videoId, url);

	return videoAvailable;
};

const extractVideoId = (url: string): string | null => {
	const urlObj = new URL(url);
	if (urlObj.hostname === "youtu.be") {
		return urlObj.pathname.slice(1);
	}
	if (
		urlObj.hostname === "www.youtube.com" ||
		urlObj.hostname === "youtube.com"
	) {
		return urlObj.searchParams.get("v");
	}
	return null;
};

const checkVideoStatus = async (
	videoId: string,
	videoCompleteUrl: string
): Promise<boolean> => {
	let isValidId = false;
	let isValidCompleteUrl = false;

	try {
		const validateId = Requester.get({
			url: `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`,
			success: () => {
				isValidId = true;
			},
			error: () => {
				isValidId = false;
			}
		});
		const validateCompleteUrl = Requester.get({
			url: `https://www.youtube.com/oembed?url=${videoCompleteUrl}&format=json`,
			success: () => {
				isValidCompleteUrl = true;
			},
			error: () => {
				isValidCompleteUrl = false;
			}
		});

		await validateId;
		await validateCompleteUrl;

		return isValidId && isValidCompleteUrl;
	} catch (error) {
		return false;
	}
};

const SuperProfileSchema = Yup.object().shape({
	about_you: Yup.string()
		.max(100, "O texto deve conter até 100 caracteres.")
		.required("Campo obrigatório"),
	experience_start: Yup.date().nonNullable().required("Campo obrigatório"),
	presentation_video_url: Yup.string().test(
		"is-valid-url",
		"A URL é inválida",
		async (value) => {
			if (!value) return true;
			if (!validateUrl(value)) {
				return false;
			}
			const isValid = validateYoutubeUrl(value);
			return isValid;
		}
	)
});

export default function SuperPerfilPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const personalData = store.getState().registration;
	const { isFirstAccess } = store.getState().flags;

	const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
	const [certifications, setCertifications] = useState(
		personalData.certifications
	);
	const [profilePicture, setProfilePicture] = useState(
		personalData.image || null
	);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const { logged_user } = useSelector((state: RootState) => state.tokens);

	const formik = useFormik({
		validateOnMount: true,
		initialValues: {
			about_you: personalData.about_you,
			presentation_video_url: personalData.presentation_video_url,
			experience_start: personalData.experience_start
		},
		validationSchema: SuperProfileSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			const superProfile = {
				...values,
				certifications
			} as SuperProfileState;
			superProfile.image = profilePicture ?? placeholderProfilePicture;
			dispatch(setSuperProfile({ ...superProfile }));
			if (isFirstAccess) {
				await createNewServiceProvider(store.getState().registration)
					.then(() => {
						triggerAnalyticsEvent("cp-set-super-profile", {
							has_presentation_video_url:
								superProfile.presentation_video_url !== "",
							has_certifications: superProfile.certifications.length > 0,
							is_super_profile: superProfileProgress === 100
						});
						setSubmitting(false);
						dispatch(setRegistrationDirty(false));

						getPartnerUuid().then(() => {
							navigate(`${logged_user ? "/dashboard" : ""}/cadastro/feedback`);
						});
					})
					.catch(() => {
						setSubmitting(false);
						toast.error("Houve um erro ao salvar o seu perfil.", {
							position: "bottom-center",
							autoClose: 5000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light"
						});
					});
			} else {
				await saveServiceProvider(
					store.getState().registration,
					personalData.id
				)
					.then(() => {
						triggerAnalyticsEvent("cp-set-super-profile", {
							has_presentation_video_url:
								superProfile.presentation_video_url !== "",
							has_certifications: superProfile.certifications.length > 0,
							is_super_profile: superProfileProgress === 100
						});
						setSubmitting(false);
						dispatch(setRegistrationDirty(true));

						navigate(`${logged_user ? "/dashboard" : ""}/cadastro/feedback`);
					})
					.catch(() => {
						setSubmitting(false);
						toast.error("Houve um erro ao salvar o seu perfil.", {
							position: "bottom-center",
							autoClose: 5000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light"
						});
					});
			}
		}
	});

	const superProfileProgress = useMemo(() => {
		// mudar o NUMBER_OF_ELEMENTS para 6 e incluir o cálculo do progresso para galeria de imagens
		const NUMBER_OF_ELEMENTS = 5;
		let progress = 0;
		if (formik.values.about_you) progress += 100 / NUMBER_OF_ELEMENTS;
		if (formik.values.presentation_video_url)
			progress += 100 / NUMBER_OF_ELEMENTS;
		if (formik.values.experience_start) progress += 100 / NUMBER_OF_ELEMENTS;
		if (certifications.length) progress += 100 / NUMBER_OF_ELEMENTS;
		if (profilePicture) progress += 100 / NUMBER_OF_ELEMENTS;
		return progress;
	}, [certifications, formik, profilePicture]);

	const handleToggleModal = () => {
		setShowVideoModal(!showVideoModal);
	};

	return (
		<>
			<Header title="Cadastro" trailing={<ButtonBack />} />
			<Steps active={3} steps={3} superProgress={superProfileProgress} />
			<DescriptionPage
				title="Turbine seu perfil!"
				description="Apresente o seu trabalho: compartilhe suas experiências, cursos, fotos e vídeos dos seus serviços. Assim, você se destaca nas buscas e atrai mais clientes!"
				isSuperPerfil
			/>
			<Wrapper>
				<form onSubmit={formik.handleSubmit}>
					<div className="flex flex-col gap-4 mb-10">
						<div>
							<h3 className="heading_sm">Sobre você</h3>
						</div>
						<Content>
							<ProfilePictureUploader
								setUploadedPicture={setProfilePicture}
								uploadedPicture={profilePicture}
							/>
							<Textarea
								id="about_you"
								name="about_you"
								placeholder="Escreva um breve resumo sobre você"
								label="SOBRE VOCÊ*"
								error={formik.touched.about_you && formik.errors.about_you}
								value={formik.values.about_you}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								errorMessage={formik.errors.about_you}
								showCount
								maxLength={100}
							/>

							<Input
								id="presentation_video_url"
								name="presentation_video_url"
								placeholder="Digite a URL aqui"
								label="URL DE VÍDEO DE APRESENTAÇÃO"
								value={formik.values.presentation_video_url}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								trailing={
									<button
										role="link"
										type="button"
										onClick={handleToggleModal}
										aria-label="Abrir Modal"
									>
										<IcoQuestionCircle />
									</button>
								}
								error={
									formik.touched.presentation_video_url &&
									formik.errors.presentation_video_url
								}
								errorMessage={formik.errors.presentation_video_url}
							/>
							<DatePicker
								selected={
									(formik.values.experience_start &&
										new Date(formik.values.experience_start)) ||
									null
								}
								maxDate={new Date()}
								onChange={(val) => {
									formik.setFieldValue("experience_start", val);
								}}
								onBlur={formik.handleBlur}
								wrapperClassName="w-full"
								placeholderText="mês/ano"
								showMonthYearPicker
								dateFormat="MM/yyyy"
								locale="pt-BR"
								customInput={
									<ShallowInput
										label="INÍCIO DA SUA EXPERIÊNCIA*"
										id="experience_start"
										name="experience_start"
										placeholder="00/0000"
										leading={<IcoCalendar />}
										error={
											formik.touched.experience_start &&
											formik.errors.experience_start
										}
										errorMessage={formik.errors.experience_start}
									/>
								}
							/>
						</Content>
					</div>
					<Certifications
						certifications={certifications}
						setCertifications={setCertifications}
					/>

					{/* <GalleryUploader gallery={gallery} setGallery={setGallery} /> */}

					<Button
						type="submit"
						variant={superProfileProgress === 100 ? "super-perfil" : "primary"}
						disabled={!formik.isValid || submitting}
					>
						{submitting ? (
							<Loading size="small" />
						) : (
							`Finalizar ${
								superProfileProgress === 100 ? "Super Perfil" : "cadastro"
							}`
						)}
					</Button>
				</form>
			</Wrapper>
			<ModalVideoPresentation
				open={showVideoModal}
				onClose={handleToggleModal}
			/>
		</>
	);
}
