import { useNavigate, useParams } from "react-router-dom";

import { Badge, ErrorState } from "components";

import { IcoError } from "assets/icons";
import ChatMessage from "components/ChatMessage";
import ChatVisitInformationMessage from "components/ChatMessage/ChatVisitInformationMessage";
import MessageBudgetAccepted from "components/ChatMessage/MessageBudgetAccepted";
import { LogoHeader } from "components/Header";
import { useEffect, useState } from "react";
import { getBudgetRequest } from "services/budget-request";
import {
	BudgetRequestState,
	Message
} from "store/features/budget-request/types";
import {
	formatChatDate,
	formatMessageDate,
	isTheSameDay,
	sortArrayByTimestamp
} from "utils/functions";
import BudgetRequestReply from "./budget-request-reply";
import styles from "./styles.module.scss";

export default function BudgetRequestPage() {
	const { budgetRequestId } = useParams();

	const [budgetRequestData, setBudgetRequestData] =
		useState<BudgetRequestState>();

	const [messageList, setMessageList] = useState<Message[]>([]);
	const [approvedTimestamp, setApprovedTimestamp] = useState("");

	const [errorRetrievingData, setErrorRetrievingData] =
		useState<boolean>(false);
	const navigate = useNavigate();

	const setBudgetAcceptedTimestamp = (messages: Message[] = []) => {
		const accepted = messages.find(
			(message) => message.message_type === "budget_approved_message"
		);
		setApprovedTimestamp(formatMessageDate(accepted?.created_at || ""));
	};

	useEffect(() => {
		const fetchData = async () => {
			await getBudgetRequest(budgetRequestId)
				.then((resp) => {
					if (resp) {
						const data = resp as BudgetRequestState;
						setBudgetRequestData(data);
						setMessageList(sortArrayByTimestamp(data.messages));

						switch (data.status) {
							case 4: // refused
							case 7: // expired
								navigate(`/budget-request/${budgetRequestId}/expired`);
								break;
							case 3: // accepted
							case 6: // finalized
								setBudgetAcceptedTimestamp(data.messages);
								break;
							case 1:
							case 2:
							case 5:
							default:
						}
					}
				})
				.catch((err) => {
					if (err.body?.[0].toLowerCase() === "expired link") {
						navigate(`/budget-request/${budgetRequestId}/expired`);
						return;
					}
					setErrorRetrievingData(true);
				});
		};
		fetchData();
	}, []);

	return !errorRetrievingData ? (
		<>
			<LogoHeader title="Pedido de Orçamento" />
			<div className={styles.centerTag}>
				<Badge
					type="order"
					label={`ORÇAMENTO ${
						budgetRequestId ? budgetRequestId.slice(-6) : ""
					}`}
				/>
			</div>
			<div className={styles.centerTag}>
				<Badge
					type="timestamp"
					label={
						budgetRequestData?.created_at
							? formatChatDate(budgetRequestData?.created_at)
							: ""
					}
				/>
			</div>
			<ChatMessage
				service="Envie sua proposta"
				message="Não comunique dados de contato, apenas informações sobre o serviço"
				isUserMessage={false}
			/>

			{messageList.map(
				(item, index, array) =>
					item.message_source !== 3 && (
						<>
							{index > 0 &&
								!isTheSameDay(item.created_at, array[index - 1].created_at) && (
									<div className={styles.centerTag}>
										<Badge
											type="timestamp"
											label={formatChatDate(item.created_at)}
										/>
									</div>
								)}
							{item.message_type === "budget_details" && item.extra_info ? (
								<ChatVisitInformationMessage
									message={item.message}
									timestamp={formatMessageDate(item.created_at)}
									profilePic={budgetRequestData?.service_provider.image}
									extraInfo={item.extra_info}
								/>
							) : (
								<ChatMessage
									message={item.message}
									timestamp={formatMessageDate(item.created_at)}
									isUserMessage={item.message_source === 2}
									profilePic={budgetRequestData?.service_provider.image}
									key={item.id}
								/>
							)}
						</>
					)
			)}
			{budgetRequestData?.status === 3 || budgetRequestData?.status === 6 ? (
				<MessageBudgetAccepted
					acceptedTimestamp={approvedTimestamp}
					franchisee={budgetRequestData?.franchisee}
				/>
			) : (
				<BudgetRequestReply
					budgetRequestId={budgetRequestId ?? ""}
					setErrorRetrievingData={setErrorRetrievingData}
					profilePic={budgetRequestData?.service_provider.image}
					firstUserMessage={
						messageList.find(
							(msg) =>
								msg.message_source === 2 &&
								msg.message_type === "budget_details"
						) || messageList.find((msg) => msg.message_source === 2)
					}
				/>
			)}
		</>
	) : (
		<ErrorState
			title="Houve um erro ao carregar seus dados"
			message="Entre em contato com nosso atendimento."
			icon={<IcoError />}
		/>
	);
}
