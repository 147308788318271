import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { FlagsState } from "./types";

const initialState = {
	isFirstAccess: true,
	isRegistrationDirty: false
} as FlagsState;

export const flagsSlice = createSlice({
	name: "flags",
	initialState,
	reducers: {
		setFirstAcess: (state, action: PayloadAction<boolean>) => {
			state.isFirstAccess = action.payload;
		},
		setRegistrationDirty: (state, action: PayloadAction<boolean>) => {
			state.isRegistrationDirty = action.payload;
		}
	}
});

export const { setFirstAcess, setRegistrationDirty } = flagsSlice.actions;
export default flagsSlice.reducer;
