import ChatMessage from "..";
import { MessageBudgetAcceptedProps } from "./types";

import styles from "./styles.module.scss";

export default function MessageBudgetAccepted({
	acceptedTimestamp,
	franchisee
}: MessageBudgetAcceptedProps) {
	const capitalizeWords = (str: string) => {
		const lowered = str.toLowerCase();
		return lowered.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
			letter.toUpperCase()
		);
	};

	const formatPostalCode = (postalCode: string) => {
		return postalCode.replace(/(\d{5})(\d{3})/, "$1-$2");
	};

	return (
		<div>
			<ChatMessage isUserMessage={false} timestamp={acceptedTimestamp}>
				<p className={styles.acceptedBudget}>Orçamento aceito pelo cliente</p>
			</ChatMessage>
			{franchisee && (
				<ChatMessage
					service="Localização do cliente"
					isUserMessage={false}
					timestamp={acceptedTimestamp}
				>
					<p className={styles.franchiseeName}>{franchisee.name}</p>
					<p className={styles.locationInformation}>
						<span className={styles.infoLabel}>Endereço: </span>
						<span>
							{capitalizeWords(franchisee.address)}, {franchisee.address_number}{" "}
							{franchisee.address_additional_info
								? ` ${franchisee.address_additional_info} `
								: " "}
							- {capitalizeWords(franchisee.address_neighborhood)},{" "}
							{capitalizeWords(franchisee.address_city)} -{" "}
							{franchisee.address_state.toLocaleUpperCase()},{" "}
							{formatPostalCode(franchisee.address_postal_code)}
						</span>
					</p>
					<p className={styles.locationInformation}>
						<span className={styles.infoLabel}>Horários: </span>
						<span>Todos os dias, das 11:00 às 22:00</span>
					</p>
				</ChatMessage>
			)}
			<ChatMessage isUserMessage={false} showHeader={false}>
				<p>
					O cliente recebeu seu número e pode entrar em contato diretamente pelo
					WhatsApp.
				</p>
			</ChatMessage>
		</div>
	);
}
