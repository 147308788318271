import { useFormik } from "formik";
import DatePicker from "react-datepicker";

import {
	Button,
	Checkbox,
	Input,
	ModalBudgetRequestRefuseConfirmation,
	ShallowInput,
	Textarea
} from "components";

import { IcoCalendar } from "assets/icons";
import { MessageReply } from "components/ChatMessage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { rejectBudgetRequest, sendMessage } from "services/budget-request";
import { getFormattedHoursAndMinutes } from "utils/functions";
import styles from "./styles.module.scss";
import {
	BudgetRequestReplyModel,
	BudgetRequestReplyModelProps,
	BudgetRequestReplyProps
} from "./types";

export default function BudgetRequestReply({
	profilePic,
	budgetRequestId,
	firstUserMessage,
	setErrorRetrievingData
}: BudgetRequestReplyProps) {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [initialValues, setInitialValues] =
		useState<BudgetRequestReplyModelProps>(
			BudgetRequestReplyModel.initialValues
		);
	const [nightVisit, setNightVisit] = useState<boolean>(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!firstUserMessage || !firstUserMessage.extra_info) return;

		const newValues: BudgetRequestReplyModelProps = {
			message: "",
			isFirstMessage: false
		};
		if (firstUserMessage.message_type === "budget_details") {
			newValues.visitDatetime = new Date(
				firstUserMessage.extra_info.visit_datetime
			);
			newValues.visitAmount = firstUserMessage.extra_info.amount;
			newValues.isVisitOnly = firstUserMessage.extra_info.is_visit_only;
			newValues.providerName = firstUserMessage.extra_info.provider_name || "";
			newValues.providerFiscalDocument =
				firstUserMessage.extra_info?.provider_fiscal_document || "";

			setNightVisit(
				newValues.visitDatetime.getHours() >= 22 ||
					newValues.visitDatetime.getHours() < 6
			);
		}
		setInitialValues(newValues);
	}, [firstUserMessage]);

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnMount: true,
		validationSchema: BudgetRequestReplyModel.validationSchema,
		onSubmit: async (values) => {
			const parsedValues: { message: string; visit_information?: any } = {
				message: values.message
			};
			if (
				values.visitDatetime?.getTime() !==
					initialValues.visitDatetime?.getTime() ||
				values.visitAmount !== initialValues.visitAmount ||
				values.isVisitOnly !== initialValues.isVisitOnly ||
				values.providerName !== initialValues.providerName ||
				values.providerFiscalDocument !== initialValues.providerFiscalDocument
			) {
				parsedValues.visit_information = {
					visit_datetime: values.visitDatetime,
					amount: Number(values.visitAmount ?? 0).toFixed(2),
					is_visit_only: values.isVisitOnly
				};
				if (nightVisit) {
					parsedValues.visit_information.provider_name = values.providerName;
					parsedValues.visit_information.provider_fiscal_document =
						values.providerFiscalDocument;
				}
			}
			await sendMessage(parsedValues, budgetRequestId)
				.then(() => {
					navigate(`/budget-request/${budgetRequestId}/sent`);
				})
				.catch(() => {
					setErrorRetrievingData(true);
				});
		}
	});

	const handleToggleModal = () => {
		setShowModal(!showModal);
	};

	const handleRefuseBudgetRequest = async () => {
		await rejectBudgetRequest(formik.values.message, budgetRequestId)
			.then(() => {
				navigate(`/budget-request/${budgetRequestId}/rejected`);
			})
			.catch(() => {
				setErrorRetrievingData(true);
			});
	};

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<MessageReply profilePic={profilePic}>
					<VisitSection formik={formik} setNightVisit={setNightVisit} />
					{nightVisit && <NightHoursSection formik={formik} />}
					<MessageSection
						formik={formik}
						handleToggleModal={handleToggleModal}
					/>
				</MessageReply>
			</form>

			<ModalBudgetRequestRefuseConfirmation
				open={showModal}
				onClose={handleToggleModal}
				onAgree={handleRefuseBudgetRequest}
			/>
		</>
	);
}

function VisitSection({
	formik,
	setNightVisit
}: {
	formik: any;
	setNightVisit: (arg0: boolean) => void;
}) {
	const [visitAmount, setVisitAmount] = useState<string>("");
	const minDate = new Date();
	const [visitHour, setVisitHour] = useState<{ value: string; error: string }>({
		value: "",
		error: ""
	});

	useEffect(() => {
		if (!formik.values.visitDatetime) return;
		updateTimes(formik.values.visitDatetime);
	}, [formik.values.visitDatetime]);

	const updateTimes = (newDate: Date) => {
		setNightVisit(newDate.getHours() >= 22 || newDate.getHours() < 6);
		const { hours, minutes } = getFormattedHoursAndMinutes(newDate);
		setVisitHour({
			...visitHour,
			value: `${hours}:${minutes}`
		});
	};

	const handleDateChange = (date: Date) => {
		if (!date) return;
		const newDate = date;
		const now = new Date();
		if (!formik.values.visitDatetime || date < now) {
			newDate.setHours(now.getHours(), now.getMinutes() + 30, 0, 0);
		}
		formik.setFieldValue("visitDatetime", newDate);
	};

	const handleAmountChange = (e: any) => {
		const { value } = e.target;
		if (!value) {
			formik.setFieldValue("visitAmount", "");
			setVisitAmount("");
			return;
		}
		const numberValue = Number(value.replace(/[^0-9]/g, ""));
		const currencyValue = (numberValue / 100).toFixed(2);
		const formattedValue = currencyValue
			.replace(".", ",")
			.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		setVisitAmount(formattedValue);

		formik.setFieldValue("visitAmount", currencyValue);
	};

	const handleTimeChange = (e: any) => {
		const { value } = e.target;
		if (!value) {
			setVisitHour({ value: "", error: "Informe uma hora válida" });
			return;
		}

		const numberValue = Number(value.replace(/[^0-9]/g, ""));
		const formattedValue = numberValue.toString().padStart(4, "0");
		const hours = formattedValue.slice(0, 2);
		const minutes = formattedValue.slice(2);
		const newDate = new Date(formik.values.visitDatetime);
		newDate.setHours(Number(hours), Number(minutes), 0, 0);

		if (newDate <= new Date()) {
			setVisitHour({
				value,
				error: "Informe uma hora futura"
			});
			return;
		}

		setNightVisit(Number(hours) >= 22 || Number(hours) < 6);
		setVisitHour({
			value,
			error: ""
		});

		formik.setFieldValue("visitDatetime", newDate);
	};

	return (
		<section className={styles.formSection}>
			<h3 className={styles.sectionTitle}>Disponibilidade e Valores</h3>
			<span className={styles.sectionDescription}>
				Selecione a data e horário mais próximos em que você estiver disponível
				e o valor de sua visita ou serviço.
			</span>
			<span className={styles.sectionDescription}>
				Se precisar de mais detalhes sobre o serviço para propor uma data, deixe
				estes campos em branco.
			</span>
			<div className={styles.inlineInputs}>
				<DatePicker
					selected={
						(formik.values.visitDatetime &&
							new Date(formik.values.visitDatetime)) ||
						null
					}
					onChange={handleDateChange}
					onBlur={formik.handleBlur}
					minDate={minDate}
					wrapperClassName="w-full"
					dateFormat="dd/MM/yyyy"
					placeholderText="00/00/0000"
					customInput={
						<ShallowInput
							label={`DATA${formik.values.isFirstMessage ? "*" : ""}`}
							id="visitDate"
							name="visitDate"
							placeholder="00/00/0000"
							error={
								formik.touched.visitDatetime && formik.errors.visitDatetime
							}
							trailing={<IcoCalendar />}
						/>
					}
				/>
				<Input
					id="visitHour"
					name="visitHour"
					label={`HORA${formik.values.isFirstMessage ? "*" : ""}`}
					value={visitHour.value}
					onChange={handleTimeChange}
					onBlur={formik.handleBlur}
					error={visitHour.error}
					errorMessage={visitHour.error}
					disabled={!formik.values.visitDatetime}
					type="time"
				/>
			</div>
			<Input
				id="visitAmount"
				name="visitAmount"
				placeholder=" 0,00"
				label={`VALOR${formik.values.isFirstMessage ? "*" : ""}`}
				inputLeading={<span>R$&nbsp;</span>}
				value={visitAmount}
				onChange={handleAmountChange}
				onBlur={formik.handleBlur}
				error={formik.touched.visitAmount && formik.errors.visitAmount}
				errorMessage={formik.errors.visitAmount}
			/>
			<Checkbox
				id="isVisitOnly"
				name="isVisitOnly"
				label={
					<span>
						Apenas o valor da visita{formik.values.isFirstMessage ? "*" : ""}
					</span>
				}
				value="isVisitOnly"
				checked={formik.values.isVisitOnly}
				onChange={formik.handleChange}
			/>
		</section>
	);
}

function NightHoursSection({ formik }: { formik: any }) {
	const handleDocumentChange = (e: any) => {
		const { value } = e.target;
		if (!value) {
			formik.setFieldValue("providerFiscalDocument", "");
			return;
		}
		let formattedValue = value.replace(/[^0-9]/g, "");
		formattedValue = formattedValue.slice(0, 11);
		if (formattedValue.length >= 3) {
			formattedValue = `${formattedValue.slice(0, 3)}.${formattedValue.slice(
				3
			)}`;
		}
		if (formattedValue.length >= 7) {
			formattedValue = `${formattedValue.slice(0, 7)}.${formattedValue.slice(
				7
			)}`;
		}
		if (formattedValue.length >= 11) {
			formattedValue = `${formattedValue.slice(0, 11)}-${formattedValue.slice(
				11
			)}`;
		}
		formik.setFieldValue("providerFiscalDocument", formattedValue);
	};

	return (
		<section className={styles.formSection}>
			<h3 className={styles.sectionTitle}>Horário Noturno</h3>
			<span className={styles.sectionDescription}>
				A seleção de um horário noturno exige a identificação do prestador que
				irá realizar o serviço.
			</span>
			<Input
				id="providerName"
				name="providerName"
				placeholder=""
				label="NOME DO(A) PRESTADOR(A)*"
				value={formik.values.providerName || ""}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={formik.touched.providerName && formik.errors.providerName}
				errorMessage={formik.errors.providerName}
				type="text"
			/>
			<Input
				id="providerFiscalDocument"
				name="providerFiscalDocument"
				placeholder="000.000.000-00"
				label="CPF DO(A) PRESTADOR(A)*"
				value={formik.values.providerFiscalDocument || ""}
				onChange={handleDocumentChange}
				onBlur={formik.handleBlur}
				error={
					formik.touched.providerFiscalDocument &&
					formik.errors.providerFiscalDocument
				}
				errorMessage={formik.errors.providerFiscalDocument}
			/>
		</section>
	);
}

function MessageSection({
	formik,
	handleToggleModal
}: {
	formik: any;
	handleToggleModal: () => void;
}) {
	return (
		<section className={styles.formSection}>
			<h3 className={styles.sectionTitle}>Detalhes do serviço</h3>
			<Textarea
				id="message"
				name="message"
				placeholder="Adicione detalhes sobre o serviço, como requisitos necessários para o atendimento"
				label={`COMENTÁRIOS${formik.values.isFirstMessage ? "" : "*"}`}
				error={formik.touched.message && formik.errors.message}
				value={formik.values.message}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				errorMessage={formik.errors.message}
				maxLength={BudgetRequestReplyModel.messageMaxLength}
				showCount
				rows={5}
			/>
			<Button
				type="submit"
				disabled={
					(formik.dirty && !formik.isValid) ||
					(!formik.dirty && !formik.isValid)
				}
				className="mt-2"
			>
				Enviar Mensagem
			</Button>
			<Button
				type="button"
				variant="warning-text"
				onClick={handleToggleModal}
				aria-label="Go back"
			>
				Recusar Orçamento
			</Button>
		</section>
	);
}
