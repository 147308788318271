import clsx from "clsx";
import { Badge } from "components";

import ChatMessageHeader from "./ChatMessageHeader";
import styles from "./styles.module.scss";
import { ChatMessageProps, MessageReplyProps } from "./types";

export default function ChatMessage({
	title,
	category,
	service,
	message,
	children,
	timestamp,
	isUserMessage,
	profilePic,
	showHeader = true,
	isSuperProfile
}: ChatMessageProps) {
	return (
		<div>
			{showHeader && (
				<ChatMessageHeader
					isSuperProfile={isSuperProfile}
					isUserMessage={isUserMessage}
					profilePic={profilePic}
					timestamp={timestamp}
				/>
			)}

			<div
				className={clsx({
					[styles.userMessageContainer]: isUserMessage,
					[styles.messageContainer]: !isUserMessage
				})}
			>
				{typeof title === "string" && (
					<div className="heading_md">{title || ""}</div>
				)}
				{typeof category === "string" && (
					<Badge type="category" label={category || ""} />
				)}
				<div className="heading_sm">{service}</div>
				<div className={clsx(["body text-neutral-500", styles.messageContent])}>
					{message || children}
				</div>
			</div>
		</div>
	);
}

export function MessageReply({ children, profilePic }: MessageReplyProps) {
	return (
		<div>
			<ChatMessageHeader isSuperProfile isUserMessage profilePic={profilePic} />
			{Array.isArray(children) ? (
				children.map((child, index) =>
					typeof child !== "boolean" ? (
						<div
							key={`reply-${index}`}
							className={styles.userMessageContainerReply}
						>
							{child}
						</div>
					) : null
				)
			) : (
				<div className={styles.userMessageContainerReply}>{children}</div>
			)}
		</div>
	);
}
