import { BadgeSuperPerfil, Button, Wrapper } from "components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ImageFeedback from "assets/images/img-feedback.png";
import { resetRegistration } from "store/features/registration/slice";

import { logout } from "services/auth";
import styles from "./styles.module.scss";

export default function FeedbackPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [isSuperPerfil] = useState<boolean>(false);

	useEffect(() => {
		const { body } = document;
		if (isSuperPerfil) {
			body.classList.add(styles.BodySuperPerfil);
		}

		return () => {
			if (isSuperPerfil) body.classList.remove(styles.BodySuperPerfil);
		};
	}, []);

	const handleGoToDashboard = () => {
		navigate("/dashboard");
	};

	const handleLogout = () => {
		dispatch(resetRegistration());
		logout();
		navigate("/login");
	};

	return (
		<Wrapper>
			<div className={styles.container}>
				<h1 className="display_lg mb-3">Parabéns!</h1>
				{isSuperPerfil && <BadgeSuperPerfil />}
				<p className="heading_sm text-center px-10 mb-6">
					Você concluiu seu cadastro!
					<br />
					Boas vindas ao TrigUP, sua nova plataforma de negócios.
				</p>
				<img src={ImageFeedback} alt="" />
				<div className={styles.buttonFooter}>
					<Button onClick={handleGoToDashboard}>Ir para Mensagens</Button>
					<Button onClick={handleLogout} variant="outline">
						Ir para o início
					</Button>
				</div>
			</div>
		</Wrapper>
	);
}
