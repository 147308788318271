import ChatMessage from "..";
import { ChatVisitInformationMessageProps } from "./types";

import styles from "./styles.module.scss";

export default function ChatVisitInformationMessage({
	message,
	timestamp,
	profilePic,
	extraInfo
}: ChatVisitInformationMessageProps) {
	return (
		<div>
			<ChatMessage
				service="Valores e Disponibilidade"
				isUserMessage
				profilePic={profilePic}
			>
				<p>
					<span className={styles.contentLabel}>Data: </span>
					{new Date(extraInfo.visit_datetime).toLocaleDateString("pt-BR", {
						day: "2-digit",
						month: "2-digit"
					})}
				</p>
				<p>
					<span className={styles.contentLabel}>Horário: </span>
					{new Date(extraInfo.visit_datetime)
						.toLocaleTimeString("pt-BR", {
							hour: "2-digit",
							minute: "2-digit"
						})
						.replace(":", "h")}
				</p>
				<p>
					<span className={styles.contentLabel}>Valor: </span>
					R$ {Number(extraInfo.amount).toFixed(2).replaceAll(".", ",")}
				</p>
			</ChatMessage>
			{extraInfo.provider_name && extraInfo.provider_fiscal_document && (
				<ChatMessage
					service="Dados para atendimento em horário noturno"
					isUserMessage
					showHeader={false}
				>
					<p>
						<span className={styles.contentLabel}>Nome do Prestador: </span>
						{extraInfo.provider_name}
					</p>
					<p>
						<span className={styles.contentLabel}>CPF: </span>
						{extraInfo.provider_fiscal_document}
					</p>
				</ChatMessage>
			)}
			{message && (
				<ChatMessage
					message={message}
					timestamp={timestamp}
					isUserMessage
					showHeader={false}
				/>
			)}
		</div>
	);
}
