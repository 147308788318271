import { IcoDelete, IcoEdit } from "assets/icons";
import FileHelper from "helpers/file-helper";
import { useRef, useState } from "react";
import { placeholderProfilePicture } from "utils/constants";
import { ActionButton, Avatar } from "..";

interface ProfilePictureUploaderProps {
	setUploadedPicture: any;
	uploadedPicture: any;
}

export default function ProfilePictureUploader({
	setUploadedPicture,
	uploadedPicture
}: ProfilePictureUploaderProps) {
	const imageInputRef = useRef<HTMLInputElement>(null);
	const [filename, setFileName] = useState<string>();

	const handleEdit = () => {
		imageInputRef?.current?.click();
	};

	const setPictureBytes = (file: File) => {
		FileHelper.getBase64(file, (result: string) => {
			setUploadedPicture(result);
			setFileName(file.name);
		});
	};

	const removePicture = () => {
		setUploadedPicture(null);
		setFileName("");
		if (imageInputRef.current) {
			imageInputRef.current.value = "";
		}
	};

	return (
		<div className="flex gap-4">
			<Avatar src={uploadedPicture ?? placeholderProfilePicture} />
			<div className="flex flex-col gap-2">
				<div className="w-full flex gap-2">
					<ActionButton icon={<IcoEdit />} text="editar" onClick={handleEdit} />
					<input
						hidden
						ref={imageInputRef}
						type="file"
						name="uploadImage"
						accept="image/*"
						onChange={(event) => {
							if (event?.target?.files?.length) {
								setPictureBytes(event.target.files[0]);
							}
						}}
					/>
					<ActionButton
						icon={<IcoDelete />}
						text="excluir"
						onClick={removePicture}
					/>
				</div>
				<h4 className="body text-neutral-600">{filename}</h4>
				<p className="overline_sm font-semibold text-neutral-600">
					JPG OU PNG. TAMANHO MÁXIMO: 2MB
				</p>
			</div>
		</div>
	);
}
