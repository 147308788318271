import styles from "./styles.module.scss";

export default function PrivacyPolicyPage() {
	return (
		<div className={styles.policyContainer}>
			<h1>Grupo Trigo</h1>

			<p>Rua da Quitanda, 86, 3&ordm; Andar</p>

			<p>Rio de Janeiro, Rio de Janeiro, 20091-902, Brasil</p>

			<hr />
			<h2>Política de Privacidade - Grupo Trigo</h2>

			<p>Última atualização em 07/08/2023</p>

			<p>
				O Grupo Trigo é formado pelas redes alimentícias Spoleto, Koni Store,
				Gurumê, China in Box, Gendai, TrigoLab e Lebonton.
			</p>

			<hr />
			<p>
				Esta Política se aplica a todas as Marcas e negócios do Grupo Trigo
				(Spoleto, Koni Store, Gurumê, China in Box, Gendai, TrigoLab e Lebonton)
			</p>

			<p>
				As páginas na internet do Grupo Trigo têm como objetivo facilitar o
				conhecimento das marcas do Grupo, bem como oferecer produtos e serviços.
				Como parte das operações diárias, são coletados dados pessoais dos
				clientes e potenciais clientes com o intuito de fornecer produtos e
				serviços alinhados aos valores, interesses e perfil de cada tipo de
				cliente. Esta Política de Privacidade aplica -se a clientes, potenciais
				clientes, usuários e visitantes das plataformas pertencentes ao Grupo
				Trigo.
			</p>

			<p>
				Respeitamos a sua privacidade e tratamos com seriedade a segurança das
				informações que nos são confiadas. O tratamento cuidadoso de seus Dados
				Pessoais faz parte da nossa missão de proporcionar um serviço cada vez
				melhor e essa Política de Privacidade (&ldquo;Política&rdquo;) é fruto
				de um processo contínuo para proteger e utilizar suas informações da
				maneira mais segura. Nosso objetivo é estabelecer com você uma relação
				de transparência e confiança, garantindo seu direito a gerir seus Dados
				Pessoais.
			</p>

			<p>
				O aplicativo TrigUP é desenvolvido pela empresa Inspira Tecnologia e
				Inovação.
			</p>

			<p>
				Aqui você vai encontrar um guia sobre como obtemos, armazenamos,
				utilizamos e compartilhamos as suas informações. Após a leitura, você
				saberá quais dados seus podemos obter, como podemos usá -los e como
				exercer seus direitos em relação a eles. Se, ainda assim, você tiver
				dúvidas, fique à vontade para entrar em contato conosco via
				<a href="dpo@grupotrigo.com.br">dpo@grupotrigo.com.br</a> , conforme
				destacado no item 10 desta Política.
			</p>

			<p>
				Esta Política de Privacidade é regularmente revisada para assegurar a
				conformidade com leis, regulamentações e novas tecnologias, refletindo
				possíveis mudanças nas operações e práticas de negócio. é importante que
				você acesse regularmente esta Política e observe a data de atualização
				informada ao final do documento.
			</p>

			<p>
				Usos de Dados específicos de cada Serviço, não previstos aqui, serão
				regulados nos respectivos termos de uso (&ldquo;Termos de Uso&rdquo;).
			</p>

			<hr />

			<h2>Aqui você vai encontrar:</h2>

			<p>
				<a href="#basic-concepts">
					1. Conceitos básicos para compreender esta Política de Privacidade
				</a>
			</p>

			<p>
				<a href="#collected-data">2. Quais Dados coletamos sobre você?</a>
			</p>

			<p className="ml-9">
				<a href="#cookies">2.1. O que são e para que servem os cookies</a>
			</p>

			<p>
				<a href="#data-usage">3. Como usamos seus Dados?</a>
			</p>

			<p className="ml-9">
				<a href="#consent">3.1. Consentimento</a>
			</p>

			<p>
				<a href="#data-controllers">
					4. Quem são os controladores destes Dados?
				</a>
			</p>

			<p>
				<a href="#storage">5. Onde armazenamos seus Dados?</a>
			</p>

			<p>
				<a href="#access">6. Quem tem acesso aos seus Dados?</a>
			</p>

			<p className="ml-9">
				<a href="#collection">
					6.1. Como Parceiros poderão coletar Dados sobre você a partir dos
					serviços
				</a>
			</p>

			<p className="ml-9">
				<a href="#international-data-transfer">
					6.2. Transferência Internacional de Dados
				</a>
			</p>

			<p>
				<a href="#data-protection">7. Como protegemos seus Dados?</a>
			</p>

			<p>
				<a href="#personal-rights">
					8. Como exercer seus direitos enquanto titular de Dados Pessoais?
				</a>
			</p>

			<p>
				<a href="#policy-updates">
					9. Como você será informado sobre alterações nesta Política?
				</a>
			</p>

			<p>
				<a href="#contact">10. Como entrar em contato?</a>
			</p>

			<p>&nbsp;</p>

			<h3 id="basic-concepts">
				1. Conceitos básicos para compreender esta Política de Privacidade
			</h3>

			<p>
				Para facilitar a sua leitura e compreensão desta Política, apresentamos
				algumas definições úteis para sua interpretação:
			</p>

			<p>
				<strong>Agentes de tratamento: </strong>O controlador e o operador.
			</p>

			<p>
				<strong>ANPD:</strong> Autoridade Nacional de Proteção de Dados. é o
				órgão da Administração Pública Federal responsável por zelar pela
				proteção de dados pessoais e por implementar/fiscalizar o cumprimento da
				LGPD no Brasil.
			</p>

			<p>
				<strong>Banco de dados:</strong> Conjunto estruturado de dados pessoais,
				estabelecido em um ou em vários locais, em suporte eletr&ocirc;nico ou
				físico.
			</p>

			<p>
				<strong>Bases legais:</strong> Hipóteses legais que autorizam o
				tratamento de dados pessoais, por exemplo: (i) consentimento; (ii)
				cumprimento de obrigação legal ou regulatória; (iii) tratamento e uso
				compartilhado de dados necessários à execução de políticas públicas
				previstas em leis e regulamentos ou respaldadas em contratos, convênios
				ou instrumentos congêneres; (iv) realização de estudos por órgão de
				pesquisa; (v) execução de contrato ou de procedimentos preliminares
				relacionados a contrato do qual seja parte o titular; (vi) exercício
				regular de direitos em processo judicial, administrativo ou arbitral;
				(vii) proteção da vida ou da incolumidade física do titular ou de
				terceiro; (viii) tutela da saúde, exclusivamente, em procedimento
				realizado por profissionais de saúde, serviços de saúde ou autoridade
				sanitária; (ix) atender aos interesses legítimos do controlador ou de
				terceiros; e (x) proteção do crédito.
			</p>

			<p>
				<strong>Consentimento</strong>: Manifestação livre, informada e
				inequívoca pela qual o Titular concorda com o tratamento dos dados
				pessoais para uma finalidade determinada. Controlador Pessoa natural ou
				jurídica, de direito público ou privado, a quem competem as decisões
				referentes ao tratamento de dados pessoais.
			</p>

			<p>
				<strong>Cookies:</strong> São pequenos arquivos que transferimos para o
				seu navegador ou dispositivo (como celular ou tablet) que nos permitem
				reconhcer o seu navegador ou dispositivo, e saber como e quando as
				páginas e as Plataformas são visitadas, bem como quantas pessoas acessam
				asPlataformas. Eles podem ser úteis para, por exemplo, fazermos as
				Plataformas se encaixarem na sua tela, entender melhor as suas
				preferências e lhe oferecer Produtos, Negócios e Experiências
				maiseficientes.
			</p>

			<p>
				<strong>Dado Pessoal:</strong> Qualquer informação que possa identificar
				ou tornar identificável uma pessoa natural (Titular). Entende-se como
				identificável uma informação que direta ou indiretamente e/ou em
				conjunto com outras informações, possa identificar uma pessoa (em
				especial por referência a um número de identificação ou a um ou mais
				fatores específicos da identidade física, fisiológica, mental,
				econ&ocirc;mica, cultural ou social).
			</p>

			<p>
				<strong>Dado Sensível:</strong> Todo dado pessoal sobre origem racial ou
				ética, convicção religiosa, opinião política, filiação a sindicato ou a
				organização de caráter religioso, filosófico ou político, dado referente
				à saúde ou à vida sexual e dado genético ou biométrico, quando vinculado
				a uma pessoa natural;
			</p>

			<p>
				<strong>Dados anonimizados</strong>: Dado relativo ao titular que não
				possa ser identificado, considerando a utilização de meios técnicos
				razoáveis e disponíveis na ocasião do tratamento.
			</p>

			<p>
				<strong>Encarregado de Dados</strong>: Pessoa indicada pelo controlador
				e operador para atuar como canal de comunicação entre o controlador, os
				titulares dos dados e a ANPD.
			</p>

			<p>
				<strong>Grupo Trigo</strong>: Para os fins da presente Política,
				considera-se a sociedade GRUPO TRIGO EMPREENDIMENTOS E PARTICIPACOES
				LTDA., sociedade empresária com sede na Rua da Quitanda, n.&ordm; 86,
				salas 301, CEP 20091-902, Cidade do Rio de Janeiro, Estado do Rio de
				Janeiro, inscrita no CNPJ sob o n&ordm; 31.318.300/0001-47 e todas
				sociedades que ela detém participação societária direta ou
				indiretamente, e/ou com sócio em comum, existentes ou que venham a
				existir.
			</p>

			<p>
				<strong>LGPD</strong>: Lei Geral de Proteção de Dados Pessoais (Lei
				n&ordm; 13.709/2018). A lei traz regras e princípios protetivos para as
				empresas tratarem as informações de pessoas físicas. O objetivo da lei é
				que as pessoas tenham mais privacidade, liberdade, transparência e
				controle em relação aos seus dados pessoais.
			</p>

			<p>
				<strong>Operador</strong>: Pessoa natural ou jurídica, de direito
				público ou privado, que realiza o tratamento de dados pessoais em nome
				do Controlador.
			</p>

			<p>
				<strong>Plataforma:</strong> São os sites, e-commerce e aplicativos
				administrados por ou para as empresas do Grupo Trigo.
			</p>

			<p>
				<strong>Titular dos Dados:</strong> Titular é a pessoa natural a quem se
				referem os dados pessoais que são objeto de tratamento;
			</p>

			<p>
				<strong>Tratamento: </strong>Abrange uma série de operações efetuadas
				sobre dados pessoais, incluindo a coleta, registro, organização,
				produção, recepção, estruturação, conservação, adaptação ou alteração,
				recuperação, classificação, a consulta, utilização, armazenamento,
				avaliação ou controle da informação, divulgação por transmissão, difusão
				ou qualquer outra forma de disponibilização, a comparação ou
				interconexão, limitação, descarte, apagamento ou destruição de dados
				pessoais.
			</p>

			<p>
				<strong>Usuário:</strong> Toda e qualquer pessoa física (Titular) que
				navegue ou faça uso da Plataforma.
			</p>

			<h3 id="collected-data">2. Quais Dados coletamos sobre você?</h3>

			<p>A coleta e Dados Pessoais observa às seguintes regras:</p>

			<ul>
				<li>
					Apenas são coletadas informações indispensáveis para a prestação dos
					serviços oferecidos.
				</li>
				<li>
					Sempre o que for necessário coletar novos dados, você será avisado
					e/ou será solicitada sua autorização, acompanhado da devida
					justificativa.
				</li>
				<li>
					Os dados pessoais coletados somente são utilizados para cumprir com as
					finalidadesinformadas anteriormente.
				</li>
			</ul>

			<p>
				<strong>Dados que você nos fornece: </strong>São os dados pessoais
				inseridos ou encaminhados ao acessar um dos nossos canais (sites ou
				aplicativos) ou a contratar produtos e/ou serviços oferecidos.
			</p>

			<p>
				<strong>Dados fornecidos por terceiros: </strong>Recebimento dos seus
				dados pessoais por intermédio de terceiros, sejam parceiros ou
				prestadores de serviços que possuam algum relacionamento com você. é
				possível ainda a coleta dos seus dados provenientes de bases públicas,
				sempre respeitando a sua privacidade.
			</p>

			<p>
				<strong>Dados de Navegação(coletados automaticamente): </strong>
				São dados coletados enquanto você visita nosso site. Localização
				derivada do endereço de IP, dados técnicos, geolocalização e localização
				em tempo real, computadores, telefones e outros dispositivos que
				utilizam algum tipo de conexão de rede (Wi-Fi, 4g, LTE, VPN).
			</p>

			<p>
				Os Dados Pessoais que tratamos variam de acordo as finalidades de uso,
				inclusive as indicadas nessa Política, e com as atividades que
				realizamos. Esses Dados Pessoais podem incluir, dentre outros, os
				seguintes:
			</p>

			<p>
				<strong>Dados pessoais: </strong>nome, data de nascimento, sexo, CPF,
				data de nascimento, endereço residencial e comercial, e celular, e-mail.
			</p>

			<p>
				<strong>Dados cadastrais de interessados em franquias: </strong>nome,
				CPF, E-mail, telefone, celular, data de nascimento, capital disponível
				para investimento, como tomou conhecimento da franquia, UF de interesse,
				cidade de interesse Informações sobre seus dispositivos: informações
				sobre o seu dispositivo (tais como Advertising ID e informações
				técnicas, como sistema operacional, tamanho de tela), conexão (tais como
				data, hora e Endereço IP, rede utilizada), identificação do dispositivo,
				uso do dispositivo. Também poderemos coletar, se você nos autorizar
				pelos nossos Aplicativos, sua geolocalização, para segurança, prevenção
				à fraude, proteção do crédito, indicar dependências próximas e também
				oferecer produtos e serviços para você e/ou para sua comodidade.
			</p>

			<p>
				<strong>Informações sobre hábitos de navegação: </strong>
				páginas e funcionalidades acessadas em nossos Sites e Aplicativos,
				quantidade de cliques, páginas e aplicativos que originaram os
				respectivos acessos (por exemplo, se você acessar um site que possuir um
				link para os nossos Sites e Aplicativos, ou se você acessar páginas de
				terceiros a partir de links nos nossos Sites e Aplicativos).
			</p>

			<p>
				<strong>Dados de mídias e plataformas sociais: </strong>
				interações que você possa ter com nossas redes sociais, tais como
				Facebook, Twitter, Instagram, LinkedIn e YouTube.
			</p>

			<h3 id="cookies">2.1. O que são e para que servem os cookies</h3>

			<p>
				Cookies são pequenos arquivos de texto enviados e armazenados no seu
				computador. Estes pequenos arquivos servem para reconhecer, acompanhar e
				armazenar a sua navegação como usuário na Internet.
			</p>

			<p>
				Quando você visita um website, o cookie é enviado para o seu computador
				ou celular, ficando armazenado em uma pasta localizada dentro do
				navegador. Fique tranquilo, os cookies não transferem vírus ou malware
				para os seus dispositivos eletr&ocirc;nicos, pois não são capazes de
				alterar seu funcionamento. Sendo assim, atuam comotracking (registro de
				atividades do usuário), sendo atualizados todas as vezes em que o
				website for acessado.
			</p>

			<p>
				O uso de cookies para acompanhar e armazenar informações possibilitará
				ao Grupo Trigo oferecer um serviço mais personalizado, de acordo com as
				características e interesses de seus usuários, permitindo, inclusive, a
				oferta de conteúdo e publicidade específicos para cada pessoa,
				beneficiando a experiência do usuário na Internet.
			</p>

			<p>Abaixo estão as categorias de cookies que usamos no Grupo Trigo:</p>

			<p>
				<strong>Necessários: </strong>Necessários para o funcionamento do site.
				Eles permitem que você navegue em nossos sites e use os serviços e
				recursos (por exemplo, cookies de segurança para autenticar usuários,
				evitar a utilização fraudulenta de credenciais de login e proteger os
				dados do usuário de terceiros não autorizados).
			</p>

			<p>
				<strong>Analíticos: </strong>São usados para coletar informações sobre a
				sua utilização do Site, assim podemos avaliar como são suas interações
				com o Site para melhorar não só os nossos Serviços, mas também a sua
				experiência no acesso ao Site (por exemplo, com que frequência você
				acessa o Site, informações sobre cliques nas páginas do Site, etc.).
			</p>

			<p>
				<strong>Funcionalidade: </strong>Os cookies desta categoria nos permitem
				lembrar de informações sobre o comportamento e preferências do usuário,
				como, por exemplo, uma cidade escolhida. A perda das informações
				armazenadas em um cookie de preferência pode tornar a experiência no
				website menos funcional, mas não o impede de funcionar.
			</p>

			<p>
				<strong>Publicidade: </strong>Nós utilizamos alguns cookies com o fim
				publicitário. Isto se faz para entregar anúncios e fazer campanhas
				publicitárias de acordo com um determinado público. Através desses é
				possível entregar anúncios de acordo com o seu perfil de consumo no
				site.
			</p>

			<p>
				Realizamos a coleta automatizada de informações analíticas por meio do
				serviço de web analytics oferecido pelo Google (Google Analytics) e
				Facebook (Facebook Ads), que, por meio de Cookies de Análise, identifica
				os seus padrões de navegação em nossos Sites e gera relatórios sobre
				suas atividades. Assim, é importante que você saiba que todas essas
				informações coletadas por Cookies de Análise são compartilhadas com o
				Google e Facebook em razão da prestação do serviço de web analytics. Nós
				recomendamos que você leia atentamente a política de privacidade do
				Google, disponível no link{" "}
				<a href="https://policies.google.com/privacy">
					https://policies.google.com/privacy
				</a>{" "}
				e do Facebook disponível no link{" "}
				<a href="https://www.facebook.com/policy.php">
					https://www.facebook.com/policy.php
				</a>
				, para entender sobre como o Google e Facebook tratam essas informações
				coletadas por Cookies de Análise, bem como entender a forma pela qual
				você pode atualizar, gerenciar, exportar e excluir os seus dados
				coletados.
			</p>

			<h4>Como alterar ou bloquear cookies</h4>

			<p>
				A maioria dos navegadores é configurada para aceitar automaticamente os
				cookies. Você pode, contudo, alterar a qualquer momento as configurações
				do seu navegador para bloquear cookies ou alertá-lo quando um cookie
				estiver sendo enviao ao seu dispositivo. Existem várias formas de
				gerenciar cookies, sendo possível criar um bloqueio geral para cookies,
				bloquear cookies de um site específico e até mesmo bloquear cookies de
				terceiros em relação a um site.
			</p>

			<p>
				Bloquear todos os cookies vai afetar o funcionamento da sua experiência,
				pois não será possível identificar suas preferências e recomendar
				conteúdo e publicidade relevantes. Além disso, de acordo com a escolha
				realizada, certas funcionalidades dos serviços poderão não funcionar
				idealmente ou ficarem indisponíveis, bem comoaspectos de segurança das
				informações e prevenção a fraudes poderão ser impactados.
			</p>

			<p>
				Com exceção dos Cookies essenciais que não podem ser desabilitados, pois
				isso inviabilizaria o seu acesso ao Site, você poderá, a qualquer tempo,
				desativar o uso dos demais Cookies ou até mesmo deletá-los, por meio da
				realização de ajustes nas configurações de seu navegador ou dispositivo.
				Consulte as instruções do seu navegador para saber mais sobre como
				ajustar ou alterar suas configurações, lembrando que a configuração
				desejada deve ser replicada em todos os dispositivos utilizados para
				acessar os Serviços (como computadores, smartphones, tablets).
			</p>

			<h3 id="data-usage">3. Como usamos seus Dados?</h3>

			<ul>
				<li>
					<p>
						O tratamento dos seus Dados Pessoais poderá ter diversas
						finalidades, de acordo com seu relacionamento com o Grupo Trigo.
						Utilizamos os seus Dados para as seguintes finalidades: Os
						tratamentos de dados pessoais podem ter finalidade diversas. Assim,
						apresentamos abaixo, de forma exemplificativa, as principais
						finalidades para as quais trataremos suas informações pessoais:
					</p>
				</li>
				<li>
					Para execução dos serviços, produtos e demais contratos ou diligências
					preliminares;
				</li>
				<li>
					Para o relacionamento e oferecimento de informações relacionadas aos
					produtos e serviços;
				</li>
				<li>Para cadastro e/ou criação da conta em nossas plataformas;</li>
				<li>
					Para execução dos serviços contratados: após a abertura de conta em
					nossas plataformas, será necessário utilizar os seus dados pessoais
					para desempenhar os nossos serviços e cumprir com as nossas
					obrigações, de acordo com o que for solicitado e com base no nosso
					relacionamento, cumprindo assim com as nossas obrigações contratuais;
				</li>
				<li>
					Para o cumprimento de obrigação legal ou regulatória, tais como, mas
					não se limitando: (i) resoluções; (ii) circulares; (iii) atos
					normativos; (iv) comunicados de órgãos competentes;
				</li>
				<li>
					Para o exercício regular de direitos em processo judicial,
					administrativo ou arbitral, (i) na condução dos processos de
					recebimento de reclamações em ouvidorias; (ii) no recebimento e
					elaboração de respostas a reclamações apresentadas aos órgãos de
					defesa do consumidor; e (iii) no armazenamento de informações para
					defesa em processos judiciais, administrativos ou arbitrais;
				</li>
				<li>
					Na persecução do interesse legítimo da empresa e de terceiros, sempre
					no limite de sua expectativa, e nunca em prejuízo de seus interesses,
					direitos e liberdades fundamentais, por exemplo, (i) na construção e
					avaliação do perfil dos clientes, para oferecimento de produtos
					personalizados e compatíveis com a sua necessidade (podendo ser
					alcançado a partir (a) da análise de seus hábitos e preferências nos
					vários canais de interação com a empresa; (b) do compartilhamento de
					dados com outras empresas do grupo, principalmente quando você é
					cliente ou potencial cliente dessa outra entidade; (ii) na implantação
					e desenvolvimento de nossos produtos e serviços; (iii) no
					estabelecimento de estatísticas, testes e avaliações para pesquisa e
					desenvolvimento, visando o gerenciamento e avaliação de riscos do
					negócio, a melhoria e/ou criação de novos produtos; (iv) em caso de
					reclamação, quando há citação da empresa em redes sociais ou
					plataformas específicas, com a finalidade de resolveros pontos
					reclamados, bem como adotar as medidas necessárias para resolver a
					situação da melhor maneira possível;
				</li>
				<li>
					Para a condução de atividades que visam prevenir a ocorrência de
					fraudes
				</li>
				<li>
					Para proteção de crédito concedida ou a ser concedida pela empresa;
				</li>
				<li>
					Por meio de autorização concedida por você, como em processos de (i)
					envio de marketing direito de produtos próprios ou de terceiros; (ii)
					em processos de relacionamento com os clientes, quando aatividade
					envolver dados pessoais sensíveis;
				</li>
				<li>
					Ocasionalmente, podemos enviar comunicados e pesquisas de satisfação
					como parte do nosso processo de feedback do cliente. é do nosso
					legítimo interesse obter tais opiniões para assegurar que os nossos
					serviços/produtos estão sendo prestados no mais alto nível;
				</li>
				<li>
					Para aprimorar o produto e/ou serviço oferecido, facilitar, agilizar e
					cumprir os compromissos estabelecidos entre o usuário e a empresa,
					melhorar a experiência dos usuários e fornecer funcionalidades
					específicas a depender das características básicas do usuário;
				</li>
				<li>
					Para compreender como o usuário utiliza os serviços da plataforma,
					para ajudar no desenvolvimento de negócios e técnicas;
				</li>
				<li>
					Para apresentar anúncios personalizados para o usuário com base nos
					dados fornecidos;
				</li>
				<li>
					Para aprimorar as iniciativas comerciais e promocionais, nos limites
					permitidos pela lei, e proporcionar experiências personalizadas para
					você, por exemplo, por meio de mensagens sobre novos serviços,
					publicidade, promoções, marketing por e-mail, definição de perfil de
					consumo, cruzamento de dados para criação de novas campanhas, ou
					outras formas de marketing relacionadas ao nosso serviço;
				</li>
				<li>
					Permitir o envio de avisos referentes aos nossos serviços, bem como
					nossas políticas internas, a fim de permitir que você explore todas as
					funcionalidades oferecidos por nós;
				</li>
				<li>
					Auxiliar na verificação de Contas e atividades, visando proporcionar
					segurança dentro e fora de nossos serviços, investigando atividades
					suspeitas ou violações de instrumentos contratuais;
				</li>
				<li>
					Gerar análises estatísticas sobre o uso dos serviços prestados, para
					que possamos compreender melhor as suas necessidades e interesses e,
					com isso, oferecer melhores serviços e/ou prover as informações
					relacionadas;
				</li>
				<li>
					Compartilhar as informações com terceiros na medida do necessário para
					viabilizar a prestação de serviços e respeitando os limites impostos
					pela legislação aplicável; e
				</li>
				<li>
					Caso venha visitar nossas instalações, podemos gravar a sua imagem
					através do nosso sistema de monitoramento por razões de segurança.
					Serão coletadas, eventualmente, informações pessoais para providenciar
					as suas credenciais de acesso, de forma a manter um ambiente de
					trabalho seguro.
				</li>
			</ul>

			<p>&nbsp;</p>

			<h3 id="consent">3.1. Consentimento</h3>

			<p>
				Consentimento: Manifestação livre, informada e inequívoca pela qual o
				Titular concorda com o tratamento dos dados pessoais para uma finalidade
				determinada.
			</p>

			<p>
				Caso a finalidade da coleta para qualquer tratamento de Dados Pessoais
				necessite do consentimento do Titular, esta solicitação será feita de
				maneira clara e transparente, por meio das nossas plataformas oficiais
				(websites e aplicativos do Grupo Trigo). Nestes casos, você será
				informado sobre quais Dados Pessoais são solicitados mediante
				consentimento, bem como as possveis consequências de não fornecimento
				para a execução do serviço.
			</p>

			<p>
				Você tem direito de revogar o consentimento a qualquer momento, bastando
				entrar em contato conosco.
			</p>

			<h3 id="data-controllers">4. Quem são os controladores destes Dados?</h3>

			<p>
				As empresas do Grupo Trigo compartilham entre si infraestrutura,
				sistemas e tecnologia para que você tenha uma experiência integrada,
				inovadora, eficiente e segura em todos os Serviços.
			</p>

			<p>
				Os seus Dados são controlados por empresas do Grupo Trigo listadas
				abaixo:
			</p>

			<ul>
				<li>Spoleto (SPT Franchising Ltda, CNPJ: 03.724.731/0001-78);</li>
				<li>Koni Store (FRM Franquia Ltda, CNPJ: 08.781.810/0001-34);</li>
				<li>Gurumê (Gurume Restaurante Ltda, CNPJ: 19.726.452/0001-83);</li>
				<li>LeBonton (LBT Franchising Eireli, CNPJ: 32.193.756/0001-90).</li>
				<li>
					China in Box (Trend Foods Franqueadora Ltda, CNPJ 10.849.922/0001-21).
				</li>
				<li>
					Gendai (Trend Foods Franqueadora Ltda, CNPJ 10.849.922/0001-21).
				</li>
				<li>
					TrigoLab (Cozinha Trigo Comercio e Servicos Ltda, CNPJ
					35.218.923/0001-07).
				</li>
			</ul>

			<h3 id="storage">5. Onde armazenamos seus Dados?</h3>

			<p>
				Onde quer que seus Dados sejam transferidos, armazenados ou tratados por
				nós ou por nossos Parceiros, saiba que tomaremos as medidas técnicas e
				organizacionais de segurança e confidencialidade e as proteções para
				garantir um nível adequado de proteção de Dados.
			</p>

			<p>
				Os Dados Pessoais recolhidos são armazenados no banco de dados do Grupo
				Trigo, devidamente protegidos e mantidos em sigilo, sendo acessado
				exclusivamente por profissionais habilitados, que são contratualmente
				obrigados a manter a confidencialidade das informações.
			</p>

			<p>
				Devido à natureza do negócio ou dos serviços prestados, poderá ser
				necessário transferir os Dados Pessoais para banco de dados localizados
				fora do Brasil. Em todo o caso, quando for necessário o armazenamento de
				Dados Pessoais em servidores localizados no exterior, nós asseguramos
				que tal transferência estará sujeita a garantias adequadas de segurança
				e privacidade.
			</p>

			<h3 id="access">6. Quem tem acesso aos seus Dados?</h3>

			<p>
				Podemos compartilhar seus Dados com terceiros, que seguem nossos padrões
				de segurança e confidencialidade, sempre de acordo com esta Política, os
				Termos de Uso do Serviço (quando houver) e a legislação aplicável.
			</p>

			<table className="m-5">
				<thead>
					<tr>
						<th>Parceiros</th>
						<th>Finalidade</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<p>Com parceiros estratégicos</p>
						</td>
						<td>
							<p>
								Inclusive para oferta, contratação e uso de produtos e serviços
								destes, ou ainda desenvolvidos em conjunto ou que possam ter um
								benefício para você
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>Com prestadores de serviços e fornecedoresx</p>
						</td>
						<td>
							<p>
								Fornecedores e Prestadores de serviços contratados para o
								desenvolvimento de nossas atividades
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>Com prestadores de serviços e fornecedores</p>
						</td>
						<td>
							<p>
								Situações em que o compartilhamento seja relevante ou necessário
								para criação, oferta, manutenção, funcionamento e melhoria dos
								nossos Sites e Aplicativos,
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>Com instituições financeiras</p>
						</td>
						<td>
							<p>
								Para o processamento de alguma transação de pagamento ou outras
								atividades para execução de contrato;
							</p>
						</td>
					</tr>
				</tbody>
			</table>

			<h3 id="collection">
				6.1. Como Parceiros poderão coletar Dados sobre você a partir dos
				serviços
			</h3>

			<p>
				Podemos compartilhar dados pessoais com terceiros ou parceiros de
				negócios que sejam relevantes, para fins de viabilizar a utilização do
				website e dos aplicativos ou viabilizar a prestação dos serviços. Em
				geral, o compartilhamento ocorre com base nos seguintes critérios e para
				as finalidades descritas abaixo:
			</p>

			<p>
				<strong>Com nossos parceiros e fornecedores:</strong> podemos
				compartilhar dados pessoais que coletamos a partir do site/plataformas
				com parceiros e/ou fornecedores para fins de: (a) fornecimento de
				software, sistemas antifraude e/ou ferramenta para gestão de clientes,
				(b) processamento das transações por cartão de crédito ou demais meios
				de pagamento, (c) geração de leads, prospecção de clientes e
				desenvolvimento de ações de marketing, (d) prestação de serviços
				logísticos dos pedidos realizados através do site e plataformas; (e)
				auxílio no gerenciamento de promoções; (f) prestação de serviços de
				atendimento ao consumidor; e (f) gestão e aprimoramento do website e
				plataformas. Nossos parceiros e fornecedores somente são autorizados a
				utilizar os dados pessoais para os fins específicos que eles foram
				contratados, portanto, eles não irão utilizar os seus dados pessoais
				para outras finalidades além as da prestação dos serviços previstos
				contratualmente.
			</p>

			<p>
				<strong>Para resguardar e proteger direitos:</strong> reservamo-nos o
				direito de acessar, ler, preservar e fornecer quaisquer dados pessoais e
				informações, incluindo interações suas, caso sejam necessários para
				cumprir uma obrigação legal ou uma ordem judicial; para fazer cumprir ou
				aplicar outros acordos e/ou contratos; ou proteger nossos direitos,
				propriedade ou segurança, bem como de nossos funcionários e/ou clientes.
			</p>

			<p>
				<strong>Requisição de autoridade competente:</strong> podemos
				compartilhar seus dados pessoais com terceiros (incluindo órgãos
				governamentais) a fim de responder a investigações, medidas judiciais,
				processos judiciais ou investigar, impedir ou adotar medidas acerca de
				atividades ilegais, suspeita de fraude ou situações que envolvam ameaças
				em potencial à segurança física de qualquer pessoa ou se de outra
				maneira exigido pela legislação.
			</p>

			<p>
				<strong>Para o nosso Grupo econ&ocirc;mico: </strong>podemos
				compartilhar os dados pessoais dos Titulares com as demais empresas
				pertencentes ao Grupo TRIGO, com a finalidade estritamente necessária
				para viabilizar a fabricação e a entrega de produtos e/ou a prestação
				dos nossos serviços ao Cliente.
			</p>

			<p>
				<strong>Novos negócios: </strong>se estivermos envolvidos em uma fusão,
				aquisição ou venda de todos ou de parte de seus ativos, os dados
				pessoais poderão ser transferidos para a empresa ou pessoa adquirente.
				Em circunst&acirc;ncias nas quais a identidade do controlador de seus
				dados pessoais se alterar como resultado de qualquer transação,
				realizaremos a notificação de quaisquer escolhas que os titulares possam
				ter acerca de seus dados pessoais.
			</p>

			<p>
				No decorrer do uso de nossos serviços, você pode se deparar com links
				para outros sites e/ou serviços de terceiros. Ao ser redirecionado para
				um aplicativo ou site de terceiros, você não será mais regido por essa
				Política de Privacidade ou pelos demais instrumetos contratuais
				eventualmente aplicáveis. Não somos responsáveis pelas práticas de
				privacidade de outros sites e incentivamos a sua leitura.
			</p>

			<h3 id="international-data-transfer">
				6.2. Transferência Internacional de Dados
			</h3>

			<p>
				Seus Dados eventualmente poderão ser transferidos para outros países, de
				acordo com as hipóteses previstas na legislação aplicável ao tema, para
				as finalidades previstas nesta Política de Privacidade. Nessas
				condições, os Dados Pessoais transferidos podem se sujeitaràs leis de
				jurisdições nas quais o fornecedor de serviço ou instalações estão
				localizados.
			</p>

			<p>
				Embora o Grupo Trigo possua sua sede no Brasil e os nossos produtos e
				serviços sejam destinados a pessoas localizadas no Brasil, os Dados
				Pessoais que coletamos podem ser transferidos, para atender as
				finalidades previstas nesta Política, pois alguns dos nossos
				fornecedores e parceiros podem estar localizados fora do Brasil.
			</p>

			<p>
				Isso não afasta a aplicação das leis brasileiras relacionadas à proteção
				de Dados Pessoais, de modo que estas transferências envolvem apenas
				empresas que demonstrem estar em conformidade com a nossa legislação,
				proporcionando grau de proteção igual ou superior ao previsto no
				ordenamento jurídico nacional.
			</p>

			<p>
				<em>
					Atenção: ao acessar os serviços e prover informações, você está
					concordando com o processamento, transferência e/ou armazenamento
					desta informação em outros países.
				</em>
			</p>

			<h3 id="data-protection">7. Como protegemos seus Dados?</h3>

			<p>
				Nós implementamos controles de segurança para proteger seus Dados.
				Adotamos práticas alinhadas aos padrões técnicos e regulatórios de
				mercado para segurança e privacidade de Dados, com ações em tecnologia e
				nos processos organizacionais.
			</p>

			<p>
				Nosso compromisso é zelar pelos seus Dados Pessoais e utilizá-los
				somente para as finalidades descritas nesta Política. Por isso, adotamos
				medidas técnicas e organizacionais adequadas para proteger os Dados
				Pessoais contra acesso, uso, alteração, divulgação ou destruição não
				autorizados, seja por ação acidental ou ilícita, assegurando que:
			</p>

			<ul>
				<li>
					Seus Dados Pessoais são protegidos contra acesso não autorizado;
				</li>
				<li>é garantida a confidencialidade dos Dados Pessoais;</li>
				<li>
					Será mantida e conservada a integridade e a disponibilidade dos Dados
					Pessoais;
				</li>
				<li>
					Violações ou suspeitas de violações a Dados Pessoais são reportadas de
					acordo com a legislação aplicável.
				</li>
			</ul>

			<p>
				Para garantir a privacidade e a proteção dos Dados Pessoais, são
				adotadas as práticas de segurança, entre as quais:
			</p>

			<ul>
				<li>
					Treinamentos e políticas de conscientização para colaboradores,
					atualizados sobre como evitar riscos ao Titular dos Dados e
					identificar ameaças e atividades maliciosas;
				</li>
				<li>
					Controles e restrições de acesso a Dados Pessoais, de modo que cada
					colaborador somente possa acessar os dados estritamente necessários
					para o desempenho de das funções; e
				</li>
				<li>
					Controle e monitoramento preventivo de incidentes de segurança,
					incluindo vazamento de dados, realizados pelo time de Segurança da
					Informação e por ferramentas automatizadas de segurana reconhecidas
					pelo mercado.
				</li>
			</ul>

			<p>
				Nós trabalhamos para proteger a sua privacidade e seus Dados Pessoais,
				mas, infelizmente, segurança total é algoque não depende apenas das
				nossas ações e processos. Entrada ou uso não autorizado de terceiros em
				sua conta, falha de hardware ou software que não esteja sob controle do
				Grupo Trigo e outros fatores podem comprometer a segurança dos seus
				Dados Pessoais. Por isso, sua atuação é fundamental para a manutenção de
				um ambienteseguro para todos.
			</p>

			<p>
				Você pode nos ajudar adotando boas práticas de segurança em relação à
				sua conta e aos seus dados (como, por exemplo, não compartilhar sua
				senha com terceiros), e caso você identifique ou tome conhecimento de
				algo que comprometa a segurança dos seus dados, por favor, entre em
				contato por meio do canal disponibilizado ao final desta Política.
			</p>

			<h3 id="personal-rights">
				8. Como exercer seus direitos enquanto titular de Dados Pessoais?
			</h3>

			<p>
				Nos comprometemos a garantir que você exerça os seus direitos. Se você
				quiser fazer alguma solicitação ou tirar dúvidas sobre seus Dados
				Pessoais, entre em contato com o canal de atendimento indicado ao final
				desta Política.
			</p>

			<p>Você pode apresentar solicitações, baseadas nos seguintes direitos:</p>

			<ul>
				<li>
					Acesso e confirmação da existência de tratamento dos dados: solicitar
					uma cópia: (i) das categorias e dos dados pessoais coletados sobre
					você; (ii) das categorias e das fontes específicas das quais os dados
					são coletados; (iii) da finalidade da coleta; e (iv) das categorias e
					dos terceiros específicos com quem compartilhamos dados pessoais;
				</li>
				<li>
					Atualização, correção de dados incompletos, inexatos ou
					desatualizados;
				</li>
				<li>
					Anonimização, bloqueio ou eliminação de dados desnecessários,
					excessivos ou tratados em desconformidade com o disposto na LGPD.
					Neste caso, informaremos a correção e atualização aos terceiros com os
					quais os dados pessoais tenham sido compartilhados;
				</li>
				<li>
					Portabilidade dos dados, observadas as normas aplicáveis e os segredos
					comercial e industrial;
				</li>
				<li>
					Informação das entidades públicas e privadas com as quais o
					Controlador realizou uso compartilhado de dados;
				</li>
				<li>
					Informação sobre a possibilidade de não fornecer consentimento e sobre
					as consequências da negativa;
				</li>
				<li>
					Revogação de consentimento, que poderá ser realizada a qualquer
					momento e sem &ocirc;nus, mediante manifestação expressa. Você tem o
					direito de ser informado se o fornecimento de Dados Pessoais é um
					requisito estatutário ou contratual ou um requisito necessário para
					celebrar um contrato, bem como se o titular está obrigado a fornecer
					os Dados Pessoais e as possíveis consequências do não fornecimento de
					tais dados;
				</li>
				<li>
					Solicitação de eliminação dos dados pessoais tratados com o
					consentimento, exceto nas hipóteses em que a manutenção dos dados é
					necessária ou permitida pela legislação. Neste caso, informaremos sua
					decisão aos terceiros com quem os dados pessoais tenham sido
					compartilhados;
				</li>
				<li>
					Oposição a tratamento realizado com fundamento em outras bases legais,
					em caso de descumprimento da LGPD, ressaltando que pode haver
					situações em que poderemos continuar a realizar o tratamento e recusar
					o seu pedido de oposição;
				</li>
				<li>
					Solicitação de revisão de decisões tomadas unicamente com base em
					tratamento automatizado de dads pessoais que o afetem, como decisões
					de crédito, cálculo de risco ou de probabilidade, ou outro semelhante,
					realizado pelo tratamento de dados pessoais utilizando regras,
					cálculos, instruções, algoritmos, análisesestatísticas, inteligência
					artificial, aprendizado de máquina, ou outra técnica computacional; e
				</li>
				<li>
					Solicitação de cancelamento do envio de ofertas direcionadas de
					produtos e serviços pelos nossos canais. Para exercer seus direitos
					sobre os seus dados pessoais, você pode entrar em contato conosco
					através dosendereços mencionados abaixo.
				</li>
			</ul>

			<p>
				Nós empenharemos todos os esforços para fornecer informações o mais
				rápido possível, de forma transparente, inteligível e acessível, em
				linguagem clara e simples. Por vezes, somos obrigados por lei e
				contratos a notificar terceiros sobre sua solicitação ou omitir
				informações que estejam sob segredo comercial e/ou de negócios.
			</p>

			<p>
				Podemos requisitar informações adicionais para se certificar da
				autenticidade da sua solicitação.
			</p>

			<p>
				Atenção: em algumas circunst&acirc;ncias, é possível manter alguns Dados
				armazenados e continuar a realizar o tratamento, mesmo no caso de
				solicitação de eliminação, oposição, bloqueio ou anonimização. Isso pode
				ocorrer para cumprimento de obrigações legais, contratuais e
				regulatórias, para resguardar e exercer direitos da empresa, dos
				usuários e clientes, para prevenção de atos ilícitos e em processos
				judiciais, administrativos e arbitrais, inclusive por questionamento de
				terceiros sobre as atividades e em outras hipóteses previstas em lei.
			</p>

			<h3 id="policy-updates">
				9. Como você será informado sobre alterações nesta Política?
			</h3>

			<p>
				Essa Política é um processo contínuo para proteger e utilizar suas
				informações da maneira mais segura e ética.
			</p>

			<p>
				Como estamos sempre buscando melhorar nossos Produtos e Experiências,
				esta Política de Privacidade pode passar por atualizações para refletir
				as melhorias realizadas. Desta forma, recomendamos a visita periódica a
				esta página para que você tenha conhecimento sobre as modificações
				efetivadas.
			</p>

			<p>
				Quando publicarmos alterações na Política, atualizaremos a data em que a
				última alteração foi publicada. As alterações surtirão efeitos a partir
				da atualização no website.
			</p>

			<p>
				Importante: ao continuar a acessar os Serviços depois que tais
				alterações passarem a valer, você estará concordando em estar vinculado
				à nova versão da Política.
				<br />
				&nbsp;
			</p>

			<h3 id="contact">10. Como entrar em contato?</h3>

			<p>
				Nós estamos à sua disposição para solucionar quaisquer dúvidas, atender
				pedidos e ouvir sugestões relacionados à sua privacidade.
			</p>

			<p>
				Se você acredita que seus Dados Pessoais foram usados de maneira
				incompatível com esta Política de Privacidade ou com as suas escolhas
				enquanto Titular destes Dados Pessoais, ou, ainda, se você tiver
				dúvidas, comentários ou sugestões relacionadas a esta Política, entre em
				contato conosco através do nosso Encarregado de Dados, que está à
				disposição nos seguintes endereços de contato:
			</p>

			<p>
				<strong>Encarregado:</strong> Bruno Tavares Torreira
			</p>

			<p>
				<strong>Endereço para correspondências:</strong> Rua da Quitanda,
				n&ordm; 86, Sala 301, Centro, Rio de Janeiro/RJ,CEP: 20.091- 902.
			</p>

			<p>
				<strong>E-mail para contato: </strong>
				<a href="mailto:dpo@grupotrigo.com.br">dpo@grupotrigo.com.br</a>
			</p>
		</div>
	);
}
