import clsx from "clsx";

import IcoSuperPerfil from "assets/icons/ico-super-perfil";
import LogoTrigupAvatar from "assets/icons/logo-trigup-avatar";
import GenericProfilePic from "assets/images/generic-profile-pic.png";

import styles from "./styles.module.scss";
import { ChatMessageHeaderProps } from "./types";

export default function ChatMessageHeader({
	timestamp,
	isUserMessage,
	profilePic,
	isSuperProfile
}: ChatMessageHeaderProps) {
	return (
		<div
			className={clsx([styles.container], {
				[styles.userContainer]: isUserMessage,
				[styles.franchiseContainer]: !isUserMessage
			})}
		>
			<div className={styles.avatar}>
				{isUserMessage ? (
					<img src={profilePic || GenericProfilePic} alt="" />
				) : (
					<LogoTrigupAvatar />
				)}
			</div>
			<div>
				<div className={styles.userName}>
					{isSuperProfile && <IcoSuperPerfil color="#773FAF" />}
					<h3 className="heading_sm">{isUserMessage ? "(Você)" : "TrigUP"}</h3>
				</div>
				<p
					className={clsx(["body text-neutral-500"], {
						"text-right": isUserMessage
					})}
				>
					{timestamp}
				</p>
			</div>
		</div>
	);
}
